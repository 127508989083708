<template>
  <div class="index">
    <nav class="nav" :style="style" :class="{ color: color }">
      <div>
        <img :src="iconClass" alt="logo" style="cursor: pointer;width: 290px;height: 44px;"
             @click="onClick(0,'/home')"/>
        <div>
          <span :class="{activate:activate === index}" v-for="(item,index) in headList" :key="index"
                @click="onClick(index,item.path)">{{ item.name }}</span>
        </div>
      </div>
    </nav>
    <router-view/>
    <div class="bottom">
      <!--      <icon-svg icon-class="icon-footerlogo"/>-->
      <img src="https://oss.ziyouzhaofang.com/JSMp4/footer_logo.png"
           style="width: 410px;padding-bottom: 60px;height: 62px"/>
      <div style="cursor: pointer;padding-bottom: 100px;" @click="$message.warning('正在开发中...')"><img
          src="http://ziyouzhaofang-bucket1.oss-cn-beijing.aliyuncs.com/sys/officialWebsite/download.png"></div>
      <!--      <div class="explain"><a href="javascript:void(0);" target="_blank">隐私政策</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a
                href="javascript:void(0);" target="_blank">服务条款</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                @click="$router.push('/about');activate = 2" style="cursor: pointer">联系我们
            </span>
            </div>-->
      <div class="bottom_text">陕西境云数据科技有限公司© Copyright Shaanxi Jingyun Data Technology Co., Ltd.
        陕西省西安市高新区丈八一路10号中铁西安中心1幢1单元41层14105
      </div>
      <div class="bottom_text">
        <img src="http://ziyouzhaofang-bucket1.oss-cn-beijing.aliyuncs.com/sys/officialWebsite/gs.png" alt="jh"/>
        <a href="https://beian.miit.gov.cn" style="color: #4d4f50" target="_blank">陕ICP备2022014758号-1</a>&nbsp;&nbsp;&nbsp;
        <img src="http://ziyouzhaofang-bucket1.oss-cn-beijing.aliyuncs.com/sys/officialWebsite/gs.png" alt="jh"/>
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61019002002314"
           style="color: #4d4f50">陕公网安备 61019002002314号</a>

      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'index',
  data() {
    return {
      style: {
        backgroundColor: "",
      },
      color: false,
      iconClass: 'https://jingyundata.cn/xieyi/logo_white.png',
      headList: [{name: '首页', path: '/home'}, {name: '项目介绍', path: '/product'}, {name: '关于我们', path: '/about'}],
      activate: 0
    }
  },
  watch: {
    '$route'(to) {
      this.onSetNavActivate(to.path);
    }
  },
  created() {
    switch (this.$route.path) {
      case '/home':
        this.activate = 0;
        break;
      case '/product':
        this.activate = 1;
        break;
      default:
        this.activate = 2;
    }
    this.onSetNavActivate(this.$route.path);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    onSetNavActivate(path) {
      if (path === "/home") {
        this.style.backgroundColor = "transparent";
        this.style.color = '#ffffff'
        this.iconClass = 'https://jingyundata.cn/xieyi/logo_white.png';
        this.color = false;
      } else {
        this.style.backgroundColor = "#ffffff";
        this.style.color = '#000000'
        this.iconClass = 'https://jingyundata.cn/xieyi/logo_black.png';
        this.color = false;
      }
    },
    handleScroll() {
      if (this.$route.path === '/home') {
        let scrollTop = document.documentElement.scrollTop;
        let opacity = Number((scrollTop / (scrollTop + 80)).toFixed(1));
        //设置背景颜色的透明读
        if (scrollTop && scrollTop > 20) {
          this.style.backgroundColor = `rgba(255, 255, 255,${opacity})`;
          this.style.boxShadow = '0 2px 12px 0 rgb(0 0 0 / 10%)';
          this.style.color = `rgba(0, 0, 0,${opacity})`
          this.iconClass = 'https://jingyundata.cn/xieyi/logo_black.png';
          this.color = true;
        } else if (scrollTop === 0) {
          this.style.backgroundColor = "transparent";
          this.style.boxShadow = 'none'
          this.style.color = '#ffffff'
          this.iconClass = 'https://jingyundata.cn/xieyi/logo_white.png';
          this.color = false;
        }
      }
    },
    onClick(index, path) {
      this.activate = index;
      this.$router.push({path: path})
    }
  }
}
</script>
<style lang="scss" scoped>

.nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  color: #666666;
  font-family: PingFangSC-Regular, sans-serif, "Microsoft YaHei", 微软雅黑;
  padding: 14px 0;

  .svg-icon {
    font-size: 124px;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15vw;
    margin: 0 auto;

    & > div:last-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 25%;
      font-size: 16px;
      //color: #FFFFFF;
      line-height: 21px;

      & > span {
        padding-bottom: 3px;
        cursor: pointer;

        &.activate {
          border-bottom: 2px solid #b6923e;
          font-weight: bold;
        }
      }
    }
  }
}


.bottom {
  background: #262729;
  padding: 133px 0 30px 0;
  text-align: center;

  & > .svg-icon {
    font-size: 180px;
  }

  .explain {
    font-size: 16px;
    font-weight: 400;
    color: #FEFFFF;
    line-height: 22px;
    padding: 180px 0 20px 0;
  }

  a {
    color: #FEFFFF;
  }

  a {
    text-decoration: none;
  }

  .bottom_text {
    font-size: 12px;
    font-weight: 400;
    color: #4d4f50;
    line-height: 16px;
    padding-bottom: 10px;

    img {
      vertical-align: bottom;
      padding-right: 7px;
    }
  }
}
</style>
